<template>
  <div class="digit-info">
    <el-container class="digit">
      <el-main class="main-content">
        <el-container>
          <el-header class="flex-box flex-start text-bold page-title">
            数字总概
          </el-header>
          <el-main>
            <el-row :gutter="16">
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="借出总金额"
                  :num="dataForm.lendAllMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="借入总金额"
                  :num="dataForm.borrowAllMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="个"
                  title="合同数量"
                  :num="dataForm.countAllContract"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="待收总金额"
                  :num="dataForm.receiveMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="￥"
                  title="待还总金额"
                  :num="dataForm.repaymentMoney"
                ></my-data-panel>
              </el-col>
              <el-col :md="8" :sm="12" :xs="24">
                <my-data-panel
                  unit="个"
                  title="未确认借条数量"
                  :num="dataForm.countByUnconfirmed"
                ></my-data-panel>
              </el-col>
            </el-row>
          </el-main>
        </el-container>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getDigitInfo } from "@/api/digit";

export default {
  name: "DigitInfo",
  data() {
    return {
      dataForm: {
        borrowAllMoney: 0,
        countAllContract: 0,
        countByUnconfirmed: 0,
        lendAllMoney: 0,
        receiveMoney: 0,
        repaymentMoney: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getDigitInfo().then((res) => {
        Object.assign(this.dataForm, res.data);
      });
    },
  },
};
</script>

<style lang="less">
.digit-info {
}
</style>
