import request from "@/common/request";

export function getBorrowInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.digit",
      _mt: "getBorrowInfo",
    },
  });
}

export function getDigitInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.digit",
      _mt: "getDigitInfo",
    },
  });
}

export function getLendInfo() {
  return request({
    method: "get",
    params: {
      _gp: "pc.digit",
      _mt: "getLendInfo",
    },
  });
}
